


















/**
 * Password Validator
 * @vuese
 * @group Forms
 */
import Vue from "vue";
import { Component, Emit, Watch } from "vue-property-decorator";
import zxcvbn from "zxcvbn";

@Component
/**
 * A simple form password creation validation tool.
 *
 * It provides two input fields `#password` and `#password_confirm`.
 *
 * It checks if the two fields match and reports the strength of the `password` field
 */
export default class PasswordValidator extends Vue {
  password = "";
  passwordConfirm = "";

  /**
   * Emitter that fires everytime the `#password` changes.
   * @arg {Number} strength - The higher the number, the more secure the password is...
   */
  @Emit()
  strength(val: number) {
    return val;
  }
  /**
   * Emitter that fires everytime the fields change.
   *
   * @arg {Boolean} match - if the passwords match
   *
   * @arg {String} [password] - returns the password. Use this event as a confirmation of the password being valid.
   */
  @Emit()
  match(valid: boolean, password?: string) {
    return { valid, password };
  }

  @Watch("password")
  passwordChanged() {
    const { score } = zxcvbn(this.password);
    this.$emit("strength", score);
    this.validatePassword();
  }

  @Watch("passwordConfirm")
  passwordConfirmChanged() {
    this.validatePassword();
  }

  validatePassword() {
    if (this.password == "") {
      // Password is empty, rejected
      this.match(false);
      return;
    }
    if (this.password !== this.passwordConfirm) {
      // Password is empty, rejected
      this.match(false);
    } else {
      this.match(true, this.password);
    }
  }
}
